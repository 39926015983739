<template>
  <div>
    <b-button
      size="sm"
      variant="secondary"
      class="my-2 btn-action "
      style="border-style: none;"
      @click="hasHistory() ? $router.go(-1) : $router.push('/')"
    >
      <feather-icon icon="ArrowLeftIcon" size="25" />
    </b-button>
    <b-card>
      <b-row>
        <b-col cols="12" lg="6">
          <p>
            <strong>Email:</strong>
            {{ user.email }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>Nombre:</strong>
            {{ user.name }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>Número de teléfono:</strong>
            {{ user.mobile }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>País:</strong>
            {{ user.country }}
          </p>
        </b-col>

        <b-col cols="12" lg="6">
          <p>
            <strong>Rol:</strong>
            {{ user.roleName }}
          </p>
        </b-col>
        <b-col cols="12" lg="6" v-if="user.codCli == null">
          <p>
            <strong>Código cliente temporal:</strong>
            {{ user.codCliTemp }}
          </p>
        </b-col>
        <b-col cols="12" lg="6" v-else>
          <p>
            <strong>Código cliente:</strong>
            {{ user.codCli }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>CIF:</strong>
            {{ user.CIF }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>Empresa:</strong>
            {{ user.companyName }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>Fecha registro:</strong>
            {{ user.registeredAt }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>Estado:</strong>
            {{ user.statusName }}
          </p>
        </b-col>
        <b-col
          col
          lg="8"
          offset-lg="2"
          class="text-center mt-3"
          v-if="user.codCli == null && user.role == 2"
        >
          <b-button
            class="btn-action"
            style="min-width: 100px;"
            v-b-modal.modal-1
          >
            {{ $i18n.messages.LinkClient }}
          </b-button>
        </b-col>
        <!--  <b-col
          col
          lg="8"
          offset-lg="2"
          class="text-center mt-3"
          v-if="user.codCli != null && user.role == 2"
        >
          <b-button
            class="btn-action"
            style="min-width: 100px;"
            v-b-modal.modal-4
          >
            {{ $i18n.messages.UnLinkClient }}
          </b-button>
        </b-col> -->
      </b-row>

      <b-modal id="modal-1" :title="$i18n.messages.LinkClient" hide-footer>
        <b-col md="6" sm="6" class="my-1">
          <b-form-group class="mb-0" label-size="lg" label-for="perPageSelect">
            <b-input-group size="md">
              <b-form-input id="filterInput" v-model="search" type="search" />
              <b-input-group-append>
                <b-button variant="primary" @click="listUsers()">
                  {{ $i18n.messages.Buscar }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-table
          striped
          responsive
          :items="clients"
          :fields="fields"
          @sort-changed="handleSortChange"
        >
          <template #cell(actions)="data">
            <div>
              <b-button
                class="btn-action"
                style="min-width: 100px;"
                @click="linkClient(data)"
              >
                {{ $i18n.messages.Link }}
              </b-button>
            </div>
          </template>
        </b-table>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItemsClient"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-modal>
      <b-modal id="modal-2" title="">
        <h3>Cliente asociado al usuario correctamente</h3>
        <template #modal-footer="">
          <b-button @click="ok()">
            OK
          </b-button>
        </template>
      </b-modal>
      <b-modal id="modal-3" title="">
        <p class="my-4">Ha ocurrido un error</p>
      </b-modal>
      <b-modal id="modal-4" ref="modal-4" title="">
        <h3>¿Seguro que quieres desasociar el cliente al usuario?</h3>
        <template #modal-footer="">
          <b-button @click="$refs['modal-4'].hide()">
            No
          </b-button>
          <b-button @click="desasociar()">
            Sí
          </b-button>
        </template>
      </b-modal>
    </b-card>

    <b-card v-if="$route.params.user.codCom != null">
      <h3 class="font-weight-bolder mb-2">{{ $i18n.messages.clients }}</h3>
      <b-table responsive :fields="fieldsClients" :items="listCustomers">
        <template #cell(Nombre)="data">
          <span class="cursor-pointer">
            {{ data.value }}
          </span>
        </template>
        <template #cell(Cliente)="data">
          <span class="cursor-pointer">
            {{ data.value }}
          </span>
        </template>
      </b-table></b-card
    >
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BButton,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BProgress,
} from "bootstrap-vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BTable,
    BButton,
    BFormInput,
    BInputGroup,
    BProgress,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroupAppend,
  },
  data() {
    return {
      user: {},
      pageOptions: [10, 15, 20],
      sortBy: "",
      sortDesc: "+",
      sortOptions: [
        { text: "Stock", value: "stock" },
        { text: "Date", value: "date" },
      ],
      orderOptions: [
        { text: "Desc", value: "+" },
        { text: "Asc", value: "-" },
      ],
      currentPage: 1,
      perPage: 12,
      search: "",
      sort: "",
      stacked: false,
      searchCustomer: "",
      fields: [
        {
          key: "name",
          label: "Cliente",
          tdClass: "textcell",
        },
        {
          key: "codCli",
          label: "Código",
          tdClass: "textcell",
        },
        {
          key: "Actions",
          label: "",
        },
      ],
      fieldsClients: [
        {
          key: "Nombre",
          label: this.$i18n.messages.Name,
        },
        {
          key: "Cliente",
          label: this.$i18n.messages.Code,
        },
        {
          key: "Pedido Minimo",
          label: this.$i18n.messages.MinOrder,
        },
        {
          key: "Cargo pedido minimo",
          label: this.$i18n.messages.MinOrderCharge,
        },
      ],
    };
  },
  created() {
    //this.setLoading(true);

    console.log("user: ", this.$route.params.user);
    console.log("user2: ", this.userLogged);
    this.user = this.$route.params.user;
    this.listUsers();

    if (this.userLogged.rol_id === 0) {
      console.log("codcom: ", this.user.codCom);
      this.searchCustomersForCommercial(this.user.codCom).then(() => {
        console.log(this.listCustomers);
      });
    }
  },
  computed: {
    ...mapGetters({
      clients: "users/getListUsersClient",
      totalItemsClient: "users/getTotalUsersClient",
      currentCustomer: "customers/getCurrentCustomer",
      successAdd: "carts/getSuccessAdd",
      errorAdd: "carts/getErrorAdd",
      getCodCli: "users/getCodCli",
      listCustomers: "customers/getListCustomers",
      userLogged: "auth/getUser",
    }),
  },
  watch: {
    currentPage() {
      this.listUsers();
    },
    perPage() {
      this.listUsers();
    },
    sortBy() {
      this.listUsers();
    },
    sortDesc() {
      this.listUsers();
    },
  },
  methods: {
    async linkClient(data) {
      console.log(data);
      console.log("CustomerID: ", data.item.customerID);
      console.log("UserID: ", this.user.userID);

      var obj = {
        CustomerID: data.item.customerID,
        UserID: this.user.userID,
      };
      console.log(obj);

      await this.linkClientMethod(obj).then(() => {
        console.log(this.getCodCli);
        if (this.getCodCli == "Your codCLI has been updated successfully") {
          this.$bvModal.show("modal-2");
        } else {
          this.$bvModal.show("modal-3");
        }
      });
    },
    validationForm() {
      if (this.user.rol_id === 0) {
        this.searchCustomersForCommercial(this.searchCustomer);
      }
    },
    ok() {
      console.log("ENTRA");
      this.$bvModal.hide("modal-2");
      this.$router.push({ name: "usersactive" });
    },
    async desasociar() {
      console.log("ENTRA");
      console.log("UserID: ", this.user.userID);

      var obj = {
        UserID: this.user.userID,
      };

      await this.unlinkClientMethod(obj).then(() => {
        this.$bvModal.hide("modal-4");
        this.$router.push({ name: "usersregistered" });
      });
      // this.$router.push({ name: "usersactive" });
    },
    listUsers() {
      const { search, currentPage, perPage, sortBy, sortDesc } = this;

      localStorage.setItem("searchProd", JSON.stringify(search));
      localStorage.setItem("currentPageProd", JSON.stringify(currentPage));
      localStorage.setItem("sortByProd", JSON.stringify(sortBy));
      localStorage.setItem("sortDescProd", JSON.stringify(sortDesc));

      const limit = 12;
      const offset = (currentPage - 1) * 12;
      let sort = "";
      if (sortBy) {
        sort = sortDesc + sortBy;
      }
      this.setLoading(true);
      this.searchUsersClient({
        search,
        limit,
        offset,
        sort,
        currentPage,
      }).then(() => {
        this.setLoading(false);
      });
    },
    resetFilters() {
      Object.assign(this.$data, this.customData());
    },
    hasHistory() {
      return window.history.length > 2;
    },
    handleSortChange(context) {
      // this is called when b-table with ref "mytable" hears the 'sort-changed' event
      // that it has emitted

      // sorting logic goes here
      console.log(context);
    },
    ...mapActions({
      searchUsersClient: "users/searchUsersClient",
      linkClientMethod: "users/linkClientMethod",
      unlinkClientMethod: "users/unlinkClientMethod",
      searchCustomersForCommercial: "customers/searchCustomersForCommercial",
    }),
    ...mapMutations({
      setLoading: "app/setLoading",
      recoverCurrentCustomer: "customers/recoverCurrentCustomer",
      setSuccessAdd: "carts/setSuccessAdd",
      setErrorAdd: "carts/setErrorAdd",
    }),
  },
};
</script>
